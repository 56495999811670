import React from "react"
import SEO from "../components/reusables/seo"
import Layout from "../components/reusables/Layout"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import addLineBreaks from "../helpers/addLineBreaks"
const AboutPageTemplate = ({ data }) => {
  const { title, description } = data.contentfulAboutPage
  const richTextOptions = {
    renderText: addLineBreaks,
  }
  return (
    <>
      <SEO title={title} />
      <Layout>
        <h1 className="text-4xl text-center mt-32 text-primary">{title}</h1>
        <div className="max-w-lg mx-auto mt-4 font-light text-center">
          {renderRichText(description, richTextOptions)}
        </div>
      </Layout>
    </>
  )
}

export default AboutPageTemplate

export const query = graphql`
  query($slug: String!) {
    contentfulAboutPage(slug: { eq: $slug }) {
      title
      description {
        raw
      }
    }
  }
`
